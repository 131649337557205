import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import "./App.css";
import {
	convertFileToImgData,
	downloadImageDataUrls,
} from "./tfjs_code/image_utils";

import { Tensorflow } from "./tfjs_code/tensorflow";

function App() {
	const [displayImage, setDisplayImage] = useState("");
    const [loading, setLoading] = useState(false);
	const tensorflow_symbol = useRef<Tensorflow>();
	useEffect(() => {
		// Update the document title using the browser API
		tensorflow_symbol.current = new Tensorflow();
	}, []);

	function hashCode(str: string): string {
		let hash = 0,
			i,
			chr;
		if (str.length === 0) return hash.toString();
		for (i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash.toString();
	}
	const onDrop = useCallback(async (uploadedFiles) => {
        setLoading(true);
		let result = await tensorflow_symbol.current!.getPersonImages();
		console.log("result", result);
		let person_image_data_url = await convertFileToImgData(
			uploadedFiles[0]
		).then(
			(data) => data,
			(error) => console.log(error + "we don't support this file type")
		);

		let topsClothUrl =
			"https://storage.googleapis.com/uplara_tfjs/tryonfinal/top_cloth.png";
		let topsClothMaskUrl =
			"https://storage.googleapis.com/uplara_tfjs/tryonfinal/top_cloth_argmax_mask.png";
		let topsCollarMaskUrl =
			"https://storage.googleapis.com/uplara_tfjs/tryonfinal/cloth_collar_argmax_mask.png";
		let topsPivotRatios = [2.31428576, 4.31428576, 15.8, 27.8];
		let topsSleeveType = 1.0;
		let bottomsClothUrl =
			"https://storage.googleapis.com/uplara_tfjs/tryonfinal/bottom_cloth.png";
		let bottomsClothMaskUrl =
			"https://storage.googleapis.com/uplara_tfjs/tryonfinal/bottom_cloth_mask.png";
		await tensorflow_symbol.current!.runPersonGraph(person_image_data_url);
		let imageData2Url = await tensorflow_symbol.current!.runTryon(
			topsClothUrl,
			topsClothMaskUrl,
			topsCollarMaskUrl,
			topsPivotRatios,
			topsSleeveType,
			bottomsClothUrl,
			bottomsClothMaskUrl
		);
		setDisplayImage(imageData2Url);
		let previousImages = await tensorflow_symbol.current!.getPersonImages();
		let hashImages = await previousImages.map(hashCode);
		console.log("hashImages", hashImages);
		downloadImageDataUrls(
			[imageData2Url],
			[(Math.random() + 1).toString(36).substring(7) + ".png"]
		);
        setLoading(false)

		console.log("second tryon finished");
	}, []);
	//   const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop });
	return (
		<>
			{loading ? "Loading ....." :(<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						<button>Upload an image</button>
					</div>
				)}
			</Dropzone>)}
			{displayImage && displayImage !== "" && (
				<img src={displayImage} height="600px" />
			)}
		</>
	);
}

export default App;
