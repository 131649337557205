import { StorageType, Storage_Map } from "./storage_map";
import {
  appendImagePrefix,
  convertImagesToDataUrl,
  convertWebSafeBase64ToPngFormat,
  fetchImagesFromServer,
  stripImagePrefix,
} from "./image_utils";

export class Tensorflow {
  readonly server_address = "https://tensorflowserving.uplara.com/";
  readonly person_path = this.server_address + "v1/models/person_graph:predict";
  readonly tryon_path = this.server_address + "v1/models/tryon_graph:predict";
  readonly background_path =
    "https://storage.googleapis.com/uplara_tfjs/cloth_images/c/beach.png";
  personMap: Storage_Map;
  personGraphOutputMap: Storage_Map;
  tryonGraphOutputMap: Storage_Map;
  personKey: string;

  constructor() {
    this.personMap = new Storage_Map(
      "person_output_map",
      5,
      StorageType.INDEX_DB
    );
    this.personGraphOutputMap = new Storage_Map(
      "person_graph_output_map",
      5,
      StorageType.INDEX_DB
    );
    this.tryonGraphOutputMap = new Storage_Map(
      "tryon_graph_output_map",
      15,
      StorageType.INDEX_DB
    );
    this.personKey = "INITIAL_KEY";
    this.setPersonKey();
    // get randon nummber
  }

  async setPersonKey() {
    await this.personMap.getPrimaryKey().then(
      (key) => {
        this.personKey = key;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  async postTensorflowServing(
    input: Map<string, string>,
    path: string
  ): Promise<any> {
    let data = {
      instances: [Object.fromEntries(input)],
    };
    console.log("the data is", data);
    return fetch(path, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data.predictions[0];
      });
  }
  hashCode(str: string): string {
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash.toString();
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  }
  async runPersonGraph(personImage: any): Promise<void> {
    let strippedImagePrefix = stripImagePrefix(personImage);
    let map = new Map<string, any>([
      ["person_string", { b64: strippedImagePrefix }],
    ]);
    let response = await this.postTensorflowServing(map, this.person_path);
    this.personKey = this.hashCode(strippedImagePrefix);
    await this.personMap.setItem(this.personKey, strippedImagePrefix);
    await this.personGraphOutputMap.setItem(this.personKey, response);
    console.log(this.personGraphOutputMap);
  }

  // persondataurl if this is first time passing in image
  // will return dataurl string of image
  async runTryon(
    topsClothUrl: string,
    topsClothMaskUrl: string,
    topsCollarMaskUrl: string,
    topsPivotRatios: number[],
    topsSleeveType: number,
    bottomsClothUrl: string,
    bottomsClothMaskUrl: string
  ): Promise<string> {
    let clothKey = topsClothUrl.concat(bottomsClothUrl);
    let tryonKey = this.personKey + "|" + clothKey;
    return this.tryonGraphOutputMap
      .containsKey(tryonKey)
      .then(async (tryonKeyExists) => {
        if (tryonKeyExists) {
          let tryonGraphOutput = await this.tryonGraphOutputMap.getItem(
            tryonKey
          );
          let png = appendImagePrefix(
            convertWebSafeBase64ToPngFormat(tryonGraphOutput)
          );
          return png;
        } else {
          let personGraphOutput = await this.personGraphOutputMap.getItem(
            this.personKey
          );
          console.log("personGraphOutput is", personGraphOutput);

          let tryonImageMaskInputs = await fetchImagesFromServer([
            topsClothUrl,
            topsClothMaskUrl,
            topsCollarMaskUrl,
            bottomsClothUrl,
            bottomsClothMaskUrl,
            this.background_path,
          ]).then(convertImagesToDataUrl);
          let strippedTryonInputs = tryonImageMaskInputs.map(stripImagePrefix);

          let densepose_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer"]
          );
          let human_parsing_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_1"]
          );
          let person = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_2"]
          );
          let sil_prob_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_3"]
          );
          let left_half_sleeve_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_4"]
          );
          let right_half_sleeve_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_5"]
          );
          let left_full_sleeve_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_6"]
          );
          let right_full_sleeve_argmax_mask = convertWebSafeBase64ToPngFormat(
            personGraphOutput["image_encoder_layer_7"]
          );
          let tryonMap = new Map<string, any>();
          tryonMap.set(
            "left_half_sleeve_argmax_mask_string",
            left_half_sleeve_argmax_mask
          );
          tryonMap.set(
            "left_full_sleeve_argmax_mask_string",
            left_full_sleeve_argmax_mask
          );
          tryonMap.set(
            "right_full_sleeve_argmax_mask_string",
            right_full_sleeve_argmax_mask
          );
          tryonMap.set(
            "right_half_sleeve_argmax_mask_string",
            right_half_sleeve_argmax_mask
          );
          tryonMap.set("densepose_string", densepose_argmax_mask);
          tryonMap.set("person_string", person);
          tryonMap.set("sil_string", sil_prob_mask);
          tryonMap.set("human_parsing_mask_string", human_parsing_argmax_mask);
          tryonMap.set("background_string", strippedTryonInputs[5]);
          tryonMap.set("bottom_cloth_string", strippedTryonInputs[3]);
          tryonMap.set(
            "bottom_cloth_argmax_mask_string",
            strippedTryonInputs[4]
          );
          tryonMap.set(
            "cloth_collar_argmax_mask_string",
            strippedTryonInputs[2]
          );
          tryonMap.set("top_cloth_string", strippedTryonInputs[0]);

          tryonMap.set("input_1_string", strippedTryonInputs[1]);
          let modifiedMap = new Map<string, any>();
          tryonMap.forEach((value, key) => {
            let new_value = { b64: value };
            modifiedMap.set(key, new_value);
          });
          modifiedMap.set("top_sleeve_type", topsSleeveType);
          modifiedMap.set("input_2", topsPivotRatios);

          let tryonGraphOutput = await this.postTensorflowServing(
            modifiedMap,
            this.tryon_path
          );
          this.tryonGraphOutputMap.setItem(tryonKey, tryonGraphOutput);
          let png = appendImagePrefix(
            convertWebSafeBase64ToPngFormat(tryonGraphOutput)
          );
          console.log("the ping is", png);
          return png;
        }
      });
  }
  // Will return list of all the person keys we have stored
  async getPersonImages(): Promise<string[]> {
    return await (
      await this.personMap.getExistingValues()
    ).map(appendImagePrefix);
  }

  async setDefaultIndex(index: number) {
    this.personKey = await this.personMap.setDefaultIndex(index);
    this.personKey = await this.personGraphOutputMap.setDefaultIndex(index);
  }
}