import heic2any from "heic2any";
import loadImage from "blueimp-load-image";
function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

export async function fetchImagesFromServer(
  image_urls: string[]
): Promise<HTMLImageElement[]> {
  return await Promise.all(
    image_urls.map(async (image_url) => {
      let image = new Image();
      image.crossOrigin = "anonymous";
      let image_promise = onload2promise(image);
      image.src = image_url;
      await image_promise;
      return image;
    })
  );
}
export function isSupportedImageType(fileType: string): boolean {
  let set = new Set(["image/png", "image/jpeg", "image/heic", ""]);
  return set.has(fileType);
}

export function getKeyNameFromFile(file: File): string {
  return file.name + file.lastModified;
}

export function stripImagePrefix(str: string): string {
  return str.replace(/^data:image\/(png|jpeg);base64,/, "");
}

export function convertWebSafeBase64ToPngFormat(str: string): string {
  let normalBase64String = str.replace(/-/g, "+").replace(/_/g, "/");
  return normalBase64String;
}

export function appendImagePrefix(str: string): string {
  return "data:image/png;base64," + str;
}

export async function convertFileToImgData(file: File): Promise<string> {
  let fileType = file.type;
  if (isSupportedImageType(fileType)) {
    let fileReader = new FileReader();
    let fileReaderPromise = onload2promise(fileReader);
    fileReader.readAsDataURL(file);
    await fileReaderPromise;
    let dataUrl = fileReader.result as string;
    if (fileType === "image/heic" || fileType === "") {
      let fetch_result = await fetch(dataUrl);
      let heic_blob = (await fetch_result.blob()) as Blob;
      let pngBlob = (await heic2any({
        blob: heic_blob,
        toType: "image/png",
      })) as Blob;
      let dataLoading = await loadImage(pngBlob, {});
      let data = dataLoading["image"] as HTMLImageElement;
      let dataUrls = await convertImagesToDataUrl([data]);
      return dataUrls[0];
    } else {
      let dataLoading = await loadImage(file, {});
      let data = dataLoading["image"] as HTMLImageElement;
      let dataUrls = await convertImagesToDataUrl([data]);
      return dataUrls[0];
    }
    return dataUrl;
  } else {
    return Promise.reject(file.type);
  }
}

interface OnLoadAble {
  onload: any;
}
export function onload2promise<T extends OnLoadAble>(obj: T): Promise<T> {
  return new Promise((resolve, reject) => {
    obj.onload = () => resolve(obj);
  });
}

export async function downloadImages(
  image_urls: string[]
): Promise<HTMLImageElement[]> {
  return await Promise.all(
    image_urls.map(async (image_url) => {
      let image = new Image();
      image.crossOrigin = "anonymous";
      let image_promise = onload2promise(image);
      image.src = image_url;
      await image_promise;
      return image;
    })
  );
}

export async function convertImagesToDataUrl(
  images: HTMLImageElement[]
): Promise<string[]> {
  return await Promise.all(
    images.map(async (image) => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d")!;
      context.drawImage(image, 0, 0);
      let dataUrl = canvas.toDataURL("image/png");
      return dataUrl;
    })
  );
}

export function downloadImageDataUrls(
  imageDataUrls: string[],
  names: string[]
) {
  imageDataUrls.forEach((imageDataUrl, index) => {
    let fake_link = document.createElement("a");
    console.log("entering fake link");
    fake_link.download = names[index];
    fake_link.href = imageDataUrl;
    fake_link.click();
  });
}
