import localForage from "localforage";

export enum StorageType {
  LOCALSTORAGE,
  INDEX_DB,
}

/**
 * This writes and retrives values from local storage. Two main wrapper
 * functionalities it provides is that a. it provides a namespacing functionality
 * b. has some evictionPolicy c. and stores a list of keys currently in the table and this list
 * is persistent(it is also stored in localstorage.) The reason is if you reload the page, you know what keys are currently
 * stored.We store keys in fifo order.
 * d. converts all objects to json. However, the caller is responsible to make sure everything converts to json.
 */
export class Storage_Map {
  readonly existingKeyPath = "___existingKeys___";
  existingKeys: string[];
  maxItems: number;
  storageInstance: LocalForage;
  initializationFinished: boolean;

  constructor(namespace: string, maxItems: number, storage: StorageType) {
    this.existingKeys = [];
    this.initializationFinished = false;
    this.storageInstance = localForage.createInstance({
      name: namespace,
      driver:
        storage === StorageType.INDEX_DB
          ? localForage.INDEXEDDB
          : localForage.LOCALSTORAGE,
    });
    this.storageInstance
      .getItem(this.existingKeyPath)
      .then((existingKeys: any) => {
        this.existingKeys = existingKeys !== null ? existingKeys : [];
        this.initializationFinished = true;
      });
    this.maxItems = maxItems;
  }
  async setItem(key: string, value: any): Promise<void> {
    await this.ensureChecks();
    await this.storageInstance.setItem(key, value);
    let existingKeyIndex = this.existingKeys.indexOf(key);
    console.log("existing keys are", this.existingKeys);
    if (existingKeyIndex !== -1) {
      this.existingKeys.splice(existingKeyIndex, 1);
    }
    if (this.existingKeys.length === this.maxItems) {
      let removalKey = this.existingKeys.pop()!;
      await this.storageInstance.removeItem(removalKey!);
    }
    this.existingKeys.unshift(key);
    console.log("this existing keys are", this.existingKeys);
    await this.storageInstance.setItem(this.existingKeyPath, this.existingKeys);
  }
  async getItem(key: string): Promise<any> {
    await this.ensureChecks();
    return this.storageInstance.getItem(key);
  }

  async getExistingValues(): Promise<string[]> {
    await this.ensureChecks();
    let existingValues = this.existingKeys.map(
      (key) => this.storageInstance.getItem(key) as Promise<string>
    );
    return Promise.all(existingValues);
  }

  async containsKey(key: string): Promise<boolean> {
    await this.ensureChecks();
    return this.existingKeys.includes(key);
  }

  async getPrimaryKey(): Promise<string> {
    await this.ensureChecks();
    if (this.existingKeys.length > 0) {
      return this.existingKeys[0];
    } else {
      return Promise.reject();
    }
  }

  async setDefaultIndex(index: number): Promise<string> {
    await this.ensureChecks();
    this.existingKeys.unshift(this.existingKeys.splice(index, 1)[0]);
    await this.storageInstance.setItem(this.existingKeyPath, this.existingKeys);
    return this.existingKeys[0];
  }
  /**
   * The reason we have this is becausse we provide the existing keys to the caller
   * and therefore we need to ensure that the existing keys are available before we return
   */
  async ensureChecks(): Promise<void> {
    while (!this.initializationFinished) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
    return;
  }
}
